
const Utils = {

  getFormId:  (name) => {
    let config = localStorage.getItem('config')
    if(config){
      config = JSON.parse(config)
      const forms = config.organisation.forms
      for(let i=0;i<forms.length;i++){
        if(forms[i].name == name){
            return forms[i]._id;
        }
      }
    }
    return null;
    
  },
};

export default Utils;
