import axiosInstance from "../../../api/axiosInstanceForMasterAPI";

const LoanApplicationsService = {

  create: async (data) => {
    const response = await axiosInstance.post("/api/v1/app/applications/create", data);
    return response.data;
  },
  save: async (data) => {
    const response = await axiosInstance.post("/api/v1/app/applications/save-section", data);
    return response.data;
  },
  uploadFile: async (data) => {
    const response = await axiosInstance.post("/api/v1/external/applications/save-file", data);
    return response.data;
  },
  saveFileIdAndLocation: async (data) => {
    const response = await axiosInstance.post("/api/v1/external/applications/save-location", data);
    return response.data;
  },


  
};

export default LoanApplicationsService;
