import axiosInstance from "../../../api/axiosInstanceForMasterAPI";

const AuthService = {
  init: async () => {
    const response = await axiosInstance.post("/api/v1/app/applications/init",
        {
            "org_id":localStorage.getItem('org_id')
        }
    );
    return response.data;
  },
  getConfig: async () => {
    const response = await axiosInstance.get("/api/v1/dashboard/home");
    return response.data;
  },
  getToken: async () => {
    const response = await axiosInstance.post("/api/v1/tokens/generate",{
        "global_id":localStorage.getItem('global_id')
    });
    return response.data;
  },
};

export default AuthService;
