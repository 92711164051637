import React, { useState, useEffect } from "react";

function CameraAccess(props) {
  const [browser, setBrowser] = useState("");
  const [os, setOs] = useState("");
  const [cameraDenied, setCameraDenied] = useState(false);

  // Detect the browser and OS
  useEffect(() => {
    const userAgent = navigator.userAgent;

    // Detect browser
    if (userAgent.includes("Chrome")) {
      setBrowser("Chrome");
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      setBrowser("Safari");
    } else if (userAgent.includes("Firefox")) {
      setBrowser("Firefox");
    } else {
      setBrowser("Unknown");
    }

    // Detect OS
    if (/android/i.test(userAgent)) {
      setOs("Android");
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setOs("iOS");
    } else {
      setOs("Other");
    }

    // Check camera and microphone access
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then(() => {
        props.setCameraAccess(true)
      })
      .catch(() => {
        props.setCameraAccess(false)
        setCameraDenied(true)
      }
      );
  }, []);

  // Helper function to render instructions
  const renderInstructions = () => {
    if (os === "Android") {
      return (
        <ul>
          <li>Open "Settings" on your phone.</li>
          <li>Go to "Apps" and find your browser ({browser}).</li>
          <li>Select "Permissions" and enable "Camera" and "Microphone".</li>
          <li>Reload this page.</li>
        </ul>
      );
    } else if (os === "iOS") {
      return (
        <ul>
          <li>Open "Settings" on your iPhone.</li>
          <li>Scroll down to find your browser ({browser}).</li>
          <li>Select "Camera" and "Microphone" and enable access.</li>
          <li>Reload this page.</li>
        </ul>
      );
    } else {
      return <p>Please check your browser or device settings to enable camera and microphone access.</p>;
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        {cameraDenied ? (
          <>
            <h1 style={styles.header}>Camera Access Denied</h1>
            <p style={styles.description}>
              We couldn't access your camera or microphone because it is disabled in your
              browser or device settings.
            </p>
            <div style={styles.instructions}>
              <h2 style={styles.subHeader}>How to Enable Camera/Audio:</h2>
              {renderInstructions()}
            </div>
          </>
        ) : (
          <>
          <h1 style={styles.loading}>
            <span style={styles.okTick}>✔</span>  Camera (Audio / Video) Access
          </h1></>
        )}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "linear-gradient(135deg, #673ab7, #512da8)",
    fontFamily: "'Inter', sans-serif",
    padding: "20px",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "16px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "24px",
    width: "100%",
    maxWidth: "400px",
    textAlign: "center",
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#ff4d4d",
    marginBottom: "16px",
  },
  description: {
    fontSize: "16px",
    color: "#555",
    marginBottom: "24px",
  },
  instructions: {
    textAlign: "left",
    fontSize: "16px",
    color: "#333",
    lineHeight: "1.5",
  },
  subHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "12px",
    color: "#444",
  },
  loading: {
    fontSize: "20px",
    fontWeight: "500",
    color: "#444",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  okTick: {
    color: "#4caf50",
    fontSize: "24px",
    marginRight: "8px",
  },
};

export default CameraAccess;
