// src/features/auth/pages/Login.js
import React from "react";
import "./Login.css";

const Login = () => {


  return (
    <div className="login-page">
      <div className="login-container">
        <h1 className="login-title">Welcome to {process.env.REACT_APP_NAME}!</h1>
        <p className="login-subtitle">Sign in to continue</p>
        <div className="login-ui">
        
        </div>
      </div>
    </div>
  );
};

export default Login;
