import React, { useState, useEffect } from "react";

import { useLocation } from 'react-router-dom';
import "react-datetime/css/react-datetime.css"; // Import the CSS for the datetime picker
import "./VideoCallLobby.css"
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import ScheduledCallsService from "../features/scheduledCalls/services/ScheduledCallsService";
 

const LoanApplicationDetails = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
   



 
  const [loanApplication, setLoanApplication] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState('Business');
  
  // Filter data by category
  const filteredData = loanApplication?.images?.filter(item => item.type === selectedCategory);
  
  // First item for the map
  const firstItem = filteredData?.[0] || null;
  
  
  const fetchScheduledCall = async (applicationId) => {
    try {
      const data = await ScheduledCallsService.get(applicationId);
      const imageData = await ScheduledCallsService.getImagesForApplication(applicationId);
      data.images = imageData
      setLoanApplication(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const application_id = queryParams.get('application_id');
    fetchScheduledCall(application_id);
  }, []);

  return (
    <div className="container mx-auto p-6">
 

      <div className="lobby-container">
      <h2> Loan Details for  #{loanApplication.application_id}</h2>
      <div className="lobby-details">
      <div className="detail">
          <strong>Application ID:</strong> {loanApplication.application_id}
        </div>

        <div className="detail">
          <strong>Applicant Name:</strong> {loanApplication.applicant_name}
        </div>
  

        <div className="detail">
          <strong>Quantum Required:</strong> ₹{loanApplication.amount_required}
        </div>
      
        <div className="min-h-screen flex flex-col items-center p-8 bg-gray-100">
      <h1 className="text-3xl font-bold mb-8">Image List by Category</h1>
      
      {/* Category Dropdown */}
      <div className="mb-4">
        <select 
          className="bg-white p-2 border rounded-lg"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="Business">Business</option>
          <option value="Lifestyle">Lifestyle</option>
          <option value="Collateral">Collateral</option>

        </select>
      </div>
      
      {/* Image List */}
      <div className="grid grid-cols-3 gap-4 mb-8">
        {filteredData?.length && filteredData.map((item) => (
          <div key={item.application_id} className="border rounded-lg shadow-md p-2">
            <img src={`${item.src}`} alt={item.type} className="w-full h-32 object-cover rounded" />
            <p className="text-center mt-2">{item.type}</p>
          </div>
        ))}
      </div>

      {/* Map Section */}
      {firstItem && (
        <div className="w-full h-96">
          <MapContainer 
            center={[firstItem.lat, firstItem.lng]} 
            zoom={12} 
            style={{ width: "100%", height: "100%" }}
          >
            <TileLayer 
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
            />
            <Marker position={[firstItem.lat, firstItem.lng]}>
              <Popup>{firstItem.type}</Popup>
            </Marker>
          </MapContainer>
        </div>
      )}
    </div>
    </div>
    
    </div>
    </div>
  );
};

export default LoanApplicationDetails;
