import React, { useState, useEffect } from "react";

function LocationAccess(props) {
  const [browser, setBrowser] = useState("");
  const [os, setOs] = useState("");
  const [locationDenied, setLocationDenied] = useState(false);

  // Detect the browser and OS
  useEffect(() => {
    const userAgent = navigator.userAgent;
    
    // Detect browser
    if (userAgent.includes("Chrome")) {
      setBrowser("Chrome");
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      setBrowser("Safari");
    } else if (userAgent.includes("Firefox")) {
      setBrowser("Firefox");
    } else {
      setBrowser("Unknown");
    }

    // Detect OS
    if (/android/i.test(userAgent)) {
      setOs("Android");
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setOs("iOS");
    } else {
      setOs("Other");
    }

    // Check location access
    navigator.geolocation.getCurrentPosition(
      () => {
  
        props.setLocationAccess(true); // Update the location access state to true
        setLocationDenied(false);      // Set locationDenied to false
      },
      () => {
 
 
        props.setLocationAccess(false); // Update the location access state to false
        setLocationDenied(true);        // Set locationDenied to true
      }
    );
  }, []);

  // Helper function to render instructions
  const renderInstructions = () => {
    if (os === "Android") {
      return (
        <ol style={{listStyle:'auto',padding:'revert'}}>
          <li>Open "Settings" on your phone.</li>
          <li>Go to "Apps" and find your browser ({browser}).</li>
          <li>Select "Permissions" and enable "Location".</li>
          <li>Reload this page.</li>
        </ol>
      );
    } else if (os === "iOS") {
      return (
        <ol style={{listStyle:'auto',padding:'revert'}}>
          <li>Open "Settings" on your iPhone.</li>
          <li>Scroll down to find your browser ({browser}).</li>
          <li>Select "Location" and choose "While Using the App".</li>
          <li>Reload this page.</li>
        </ol>
      );
    } else {
      return <p>Please check your browser or device settings to enable location access & <strong style={{color:'red'}}>Reload</strong> the browser.</p>;
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        {locationDenied ? (
          <>
            <h1 style={styles.header}>Location Access Denied</h1>
            <p style={styles.description}>
              We couldn't access your location because it is disabled in your
              browser or device settings.
            </p>
            <div style={styles.instructions}>
              <h2 style={styles.subHeader}>How to Enable Location:</h2>
              {renderInstructions()}
            </div>
          </>
        ) : (
          <h1 style={styles.loading}> <span style={styles.okTick}>✔</span> Location Access</h1>
        )}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "linear-gradient(135deg, #1e90ff, #00bcd4)",
    fontFamily: "'Inter', sans-serif",
    padding: "20px",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "16px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "24px",
    width: "100%",
    maxWidth: "400px",
    textAlign: "center",
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#ff4d4d",
    marginBottom: "16px",
  },
  description: {
    fontSize: "16px",
    color: "#555",
    marginBottom: "24px",
  },
  instructions: {
    textAlign: "left",
    fontSize: "16px",
    color: "#333",
    lineHeight: "1.5",
  },
  subHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "12px",
    color: "#444",
  },
  loading: {
    fontSize: "20px",
    fontWeight: "500",
    color: "#444",
  },
  okTick: {
    color: "#4caf50",
    fontSize: "24px",
    marginRight: "8px",
  }
};


export default LocationAccess;