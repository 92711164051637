import React, { useEffect, useRef, useState } from 'react';
import { connect, createLocalTracks } from 'twilio-video';
import { useLocation } from 'react-router-dom';
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import LoanApplicationsService from "../features/loanApplications/services/LoanApplicationsService";
import "react-html5-camera-photo/build/css/index.css";

const VideoInterview = ({ token, roomName }) => {
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const location = useLocation();

  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("");
  const [facingMode, setFacingMode] = useState(FACING_MODES.ENVIRONMENT);
  const [isLoading, setIsLoading] = useState(false);
  const [coordinates, setCoordinates] = useState({
    latitude: null,
    longitude: null,
    error: null,
  });

  const [orgId, setOrgId] = useState(null);
  const [applicationId, setApplicationId] = useState(roomName);
  const [globalId, setGlobalId] = useState(null);
  const [fileUploadToken, setFileUploadToken] = useState(null);

  const [localVideoTrack, setLocalVideoTrack] = useState(null);  // Track for local video

  // Open camera for category
  const openCameraForCategory = (category) => {
    setCurrentCategory(category);
    setIsCameraOpen(true);
    stopVideo();
  };

  // Handle camera error
  const handleCameraError = (error) => {
    console.error('Camera error:', error);
    alert('Camera error');
  };

  // Get location
  const setLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ latitude, longitude, error: null });
        },
        (error) => {
          setCoordinates((prevState) => ({
            ...prevState,
            error: error.message,
          }));
        }
      );
    } else {
      setCoordinates((prevState) => ({
        ...prevState,
        error: "Geolocation is not supported by this browser.",
      }));
    }
  };

  // Handle photo taking
  const handleTakePhoto = async (dataUri) => {
    if (!coordinates?.latitude) {
      alert("Please give location access!");
      return;
    }
    setIsLoading(true);

    try {
      const blob = await fetch(dataUri).then((res) => res.blob());
      const formData = new FormData();
      formData.append("file", blob, "file.jpg");
      formData.append("file_type", currentCategory);
      formData.append("org_id", orgId);
      formData.append("global_id", globalId);
      formData.append("token", fileUploadToken);

      const response = await LoanApplicationsService.uploadFile(formData);
      if (response?.data?.application_file?._id) {
        await LoanApplicationsService.saveFileIdAndLocation({
          application_id: applicationId,
          entity_id: response.data.application_file._id,
          entity_type: "file",
          tags: [`${currentCategory.toLowerCase()}_photos`],
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          token: fileUploadToken,
        });
      }
    } catch (error) {
      console.error("Error uploading photo:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Stop video
  const stopVideo = () => {
    if (localVideoTrack) {
      localVideoTrack.stop();  // Completely stop the video track and turn off the camera
      setLocalVideoTrack(null);  // Clear the track reference
    }
  };

  // Start video
  const startVideo = () => {
    setIsCameraOpen(false);
    if (!localVideoTrack) {
      createLocalTracks({ audio: true, video: true }).then((localTracks) => {
        localTracks.forEach((track) => {
          if (track.kind === "video") {
            setLocalVideoTrack(track);
            track.attach(localVideoRef.current);
          }
        });
      });
    }
  };

  useEffect(() => {
    // Location access check is now asynchronous and doesn't block the UI
    setLocation();

    const queryParams = new URLSearchParams(location.search);
    const callToken = queryParams.get("call_token");
    const roomId = queryParams.get("room_id");
    const orgId = queryParams.get("org_id");
    const fileUploadToken = queryParams.get("file_upload_token");
    const globalId = queryParams.get("global_id");

    setApplicationId(roomId);
    setOrgId(orgId);
    setFileUploadToken(fileUploadToken);
    setGlobalId(globalId);

    const attachTrack = (track, container) => {
      container.appendChild(track.attach());
    };

    const detachTrack = (track) => {
      track.detach().forEach((element) => element.remove());
    };

    const handleParticipantConnected = (participant) => {
      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          attachTrack(publication.track, remoteVideoRef.current);
        }
      });

      participant.on("trackSubscribed", (track) => {
        attachTrack(track, remoteVideoRef.current);
      });

      participant.on("trackUnsubscribed", (track) => {
        detachTrack(track);
      });
    };

    let room;

    connect(callToken, { name: roomId, tracks: [] }).then((roomInstance) => {
      room = roomInstance;

      createLocalTracks({ audio: true, video: { width: 640, height: 480 } }).then((localTracks) => {
        localTracks.forEach((track) => {
          room.localParticipant.publishTrack(track);
          if (track.kind === "video") {
            setLocalVideoTrack(track);  // Save the local video track
            attachTrack(track, localVideoRef.current);
          }
        });
      });

      room.participants.forEach(handleParticipantConnected);
      room.on("participantConnected", handleParticipantConnected);
    });

    return () => {
      if (room) {
        room.disconnect();
        room.localParticipant.tracks.forEach((publication) => {
          publication.track.stop();
        });
      }
    };
  }, [token, roomName]);

  return (
    <div className="relative w-full h-screen bg-black">
      {/* Participant's Video - Fullscreen */}
      <div
        ref={remoteVideoRef}
        className="absolute inset-0 flex items-center justify-center w-full h-full"
        style={{ backgroundColor: "grey" }}
      >
        {/* Actual video content will replace the background once it's loaded */}
      </div>

      {/* Local Video Overlay - Positioned on the top-right corner (30% width) */}
      <div
        ref={localVideoRef}
        className="absolute top-4 right-4 sm:w-1/4 md:w-1/5 w-1/3 h-auto bg-gray-900 rounded-lg overflow-hidden shadow-lg border-4 border-white"
      >
        {/* Local user's video content */}
      </div>

      {/* Video Controls */}
      {orgId && (
        <div className="fixed bottom-0 left-0 w-full bg-white bg-opacity-90 p-2 shadow-lg">
          <div className="flex justify-around">
            {["Business", "Lifestyle", "Collateral"].map((label, idx) => (
              <button
                key={idx}
                onClick={() => openCameraForCategory(label)}
                className="bg-gray-200 text-sm font-semibold text-gray-700 rounded-full px-4 py-2 shadow-md hover:bg-gray-300 transition"
              >
                {label} Photo
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Camera Modal */}
      {isCameraOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-4 relative w-11/12 max-w-md">
            {/* Close Button */}
            <button
              onClick={() => startVideo()}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 font-bold text-lg"
            >
              ✖
            </button>

            {/* Title */}
            <h2 className="text-center text-lg font-bold mb-4">{currentCategory}</h2>

            {/* Camera Component */}
            {isLoading ? (
              <div className="flex justify-center items-center">
                <img
                  src="/loader.webp"
                  alt="Loading..."
                  className="w-16 h-16 animate-spin"
                />
                Uploading...
              </div>
            ) : (
              <div className="relative">
                <Camera
                  onTakePhoto={(dataUri) => handleTakePhoto(dataUri)} // This triggers when a photo is taken
                  isFullscreen={false}
                  idealFacingMode={facingMode}
                  onCameraError={handleCameraError}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoInterview;
