import React, { useState, useEffect } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import "react-datetime/css/react-datetime.css"; // Import the CSS for the datetime picker
import ScheduledCallsService from "../features/scheduledCalls/services/ScheduledCallsService";

const LoanApplications = () => {
    const navigate = useNavigate();
  const [loans, setLoans] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedLoanIndex, setSelectedLoanIndex] = useState(null);
  const [fromDateTime, setFromDateTime] = useState(moment());
  const [tillDateTime, setTillDateTime] = useState(moment().add(1, "hour"));
  const [textToCopy, setTextToCopy] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async (loan) => {
    try {
      
      await navigator.clipboard.writeText(`Hi ${loan.applicant_name}, Your video PD is scheduled between ${formatScheduledCall(loan.call_scheduled_from,loan.call_scheduled_till)}. Meeting link : ${process.env.REACT_APP_URL}/lobby?application_id=${loan.application_id}`); // Copy the text
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleJoinNow = async (record) => {
    const callToken = record.call_token || (await ScheduledCallsService.generateCallToken(record.application_id, {})).rep_call_token;
    navigate(`/video-interview-rep?call_token=${callToken}&room_id=${record.application_id}`);
};
  const handleScheduleCall = async () => {
    if (selectedLoanIndex !== null) {
      const updatedLoans = [...loans];
      const selectedLoan = updatedLoans[selectedLoanIndex];

      // Update the schedule in the local state
      selectedLoan.call_scheduled_from = fromDateTime;
      selectedLoan.call_scheduled_till = tillDateTime;

      setLoans(updatedLoans);
      setShowModal(false);

      // Persist the schedule to the backend
      try {
         await ScheduledCallsService.updateSchedule(selectedLoan.application_id, {
          call_scheduled_from: fromDateTime,
          call_scheduled_till: tillDateTime,
        });
       
        alert("Call scheduled successfully!");
        fetchScheduledCalls();
      } catch (error) {
        console.error("Error scheduling call:", error);
        alert("Failed to schedule the call.");
      }
    }
  };

  const formatScheduledCall = (from, till) => {
    return (
      moment(from).format("Do MMM YY, hh:mm a") +
      " to " +
      moment(till).format("hh:mm a")
    );
  };

  const fetchScheduledCalls = async () => {
    try {
      const data = await ScheduledCallsService.getAll();
      setLoans(data);
    } catch (err) {
      console.error(err);
    }
  };

  const markAsCompleted = async (application_id) => {
    try {
      const y = window.confirm("Do you really wish to mark the call verification as complete?")
      if(y){
        await ScheduledCallsService.updateStatusToCompleted(application_id);
        fetchScheduledCalls();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getStatusBasedClass = (status)=> {
    let color = '';
    switch(status){
      case 'Pending':color='red';break;
      case 'Joined':color='blue';break;
      case 'Scheduled':color='yellow';break;
      case 'Completed':color='green';break;
      default: color='black'
    }
    return `bg-${color}-500`
  }

  useEffect(() => {
    fetchScheduledCalls();
  }, []);

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-semibold mb-4">Loan Application & Scheduled Calls</h2>

      <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
        <table className="min-w-full text-left text-sm text-gray-600">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-2">Application ID</th>
              <th className="px-4 py-2">Borrower Name</th>
              <th className="px-4 py-2">Mobile</th>
              <th className="px-4 py-2">Quantum</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Call Schedule</th>
              <th className="px-4 py-2">Captured Data</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loans.map((loan, index) => (
              <tr key={loan.application_id} className="border-t">
                <td className="px-4 py-2">{loan.application_id}</td>
                <td className="px-4 py-2">{loan.applicant_name}</td>
                <td className="px-4 py-2">{loan.mobile_number}</td>
                <td className="px-4 py-2">₹{loan.amount_required}</td>
                <td className="px-4 py-2">
                  <span
                    className={`px-2 py-1 rounded-full text-sm text-white ${getStatusBasedClass(loan.status)}`}
                  >
                    {loan.status}
                  </span>
                </td>
                <td className="px-4 py-2">
                {(loan.status == 'Scheduled' || loan.status=='Joined' ) && loan.call_scheduled_from ? (
                ( moment(loan.call_scheduled_from).isBefore(moment()) &&  moment(loan.call_scheduled_till).isAfter(moment()) ) ? (
                      // If the scheduled call is in the past, format the date
                      <button
                        onClick={() => handleJoinNow(loan)}
                        className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                      >
                        Join Now
                      </button>
                    ) :  (
                        <span>{formatScheduledCall(loan.call_scheduled_from,loan.call_scheduled_till)}</span>
                      
                    )
                  ) : ''}
                </td>
                <td  className="px-4 py-2">
                  <a className="px-4 py-2 bg-pink-500 text-white rounded-lg hover:bg-pink-600" href={`/loan-application-details?application_id=${loan.application_id}`}>
                  View</a>
                </td>
                <td className="px-4 py-2">
                  {loan.status === "Pending" && (
                    <button
                      onClick={() => {
                        setSelectedLoanIndex(index);
                        setShowModal(true);
                      }}
                      className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                    >
                      Schedule Call
                    </button>
                  )}

{loan.status === "Scheduled" && (
                   <><button
                      onClick={() => {
                        setSelectedLoanIndex(index);
                        setShowModal(true);
                      }}
                      className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600"
                    >
                      Reschedule Call
                    </button>
&nbsp;
&nbsp;
&nbsp;
<button
onClick={() => {
  handleCopyClick(loan)
}}
className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
>
Copy Joining Link
</button></> 
                  )}

{loan.status === "Joined" && (
                    <button
                      onClick={() => markAsCompleted(loan.application_id)}
                      className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                    >
                    Completed
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for Scheduling Call */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm">
            <h3 className="text-lg font-semibold mb-4">Schedule Call</h3>
            <div className="mb-4">
              <label className="block mb-2 text-sm">From:</label>
              <Datetime
                value={fromDateTime}
                onChange={(date) => setFromDateTime(moment(date))}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-sm">Till:</label>
              <Datetime
                value={tillDateTime}
                onChange={(date) => setTillDateTime(moment(date))}
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-500 mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleScheduleCall}
                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
              >
                Schedule
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanApplications;
