import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes,Navigate,Link } from "react-router-dom";


// Import logo image
//import logo from '../assets/logo.png'; // Adjust path to the correct location of your logo file

const MainLayout = ({ user, handleLogout }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="main-layout">
      {/* Stylish Menu */}
      <header className="bg-black text-white sticky top-0 z-50 shadow-lg">
        <div className="container mx-auto px-4 py-4 flex items-center justify-between">
          {/* Logo */}
          <div className="flex items-center">
            <img src={`/logo192.png`} alt="Logo" className="h-10" /> {/* Adjust logo size using h-10 */}
          </div>

          {/* Hamburger Menu Button for Mobile */}
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="sm:hidden text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>

          {/* Navigation Links (Desktop and Mobile) */}
          <nav
            className={`${
              menuOpen ? 'block' : 'hidden'
            } absolute top-full left-0 w-full bg-blue-600 sm:bg-transparent sm:static sm:flex sm:items-center`}
          >
            <ul className="sm:flex sm:space-x-4 space-y-2 sm:space-y-0 p-4 sm:p-0">
              <li>
                <a href="/" className="block px-2 py-1 hover:bg-blue-700 rounded sm:hover:bg-transparent">
                  &nbsp;&nbsp;&nbsp; Home
                </a>
              </li>
    
              <li>
                <a href="/new-loan-application" className="block px-2 py-1 hover:bg-blue-700 rounded sm:hover:bg-transparent">
                  New Loan Application
                </a>
              </li>

              <li>
                <a
                  className="block px-2 py-1 hover:bg-blue-700 rounded sm:hover:bg-transparent"
                  style={{ cursor: 'pointer' }}
                  onClick={handleLogout}
                >
                  {user ? `Logout` : 'Log In'}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <Outlet />

      <footer>
        <center>
          <p>&copy; {new Date().getFullYear()} {process.env.REACT_APP_NAME}. All rights reserved.</p>
        </center>
      </footer>
    </div>
  );
};

export default MainLayout;
