import axios from "axios";
import Cookies from "js-cookie";
 

// Create an Axios instance
const axiosInstanceForMasterAPI = axios.create({
  baseURL: `${process.env.REACT_APP_MASTER_API_BASE_URL}`, // Update this with your API's base URL
  header: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// Add interceptors if needed (e.g., for token management)
axiosInstanceForMasterAPI.interceptors.request.use(
  (config) => {
    // Add token from localStorage or any authentication mechanism
    const orgId = localStorage.getItem('org_id')// localStorage.getItem("authToken");
    if (orgId) {
      config.headers.Authorization = 'Bearer '+Cookies.get('ac-ses-id'); 
      config.headers["x-tenant-id"] = orgId
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstanceForMasterAPI;
