import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate } from "react-router-dom";
import LoadingView from "./components/LoadingView/LoadingView";
import HomePage from "./pages/HomePage";

import Login from "./features/auth/pages/Login";

import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";


import axios from 'axios';

import VideoInterview from './pages/VideoInterview';
import NewLoanApplication from './pages/NewLoanApplication';
import LoanApplications from './pages/LoanApplications';
import VideoCallLobby from './pages/VideoCallLobby';
import LoanApplicationDetails from './pages/LoanApplicationDetails';


import AuthService from './features/auth/services/AuthService';

//import Dashboard from "./pages/Dashboard"; // Example dashboard page




const handleLogout =()=> {

}
function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Tracks loading state

  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
 
    const orgId = urlParams.get('org_id')
    if(orgId){
      localStorage.setItem('org_id',orgId);
    }

    
      const authenticate = async () => {
        try {
          const response =  await AuthService.init()
   
          if(response?.data?.global_id){
            localStorage.setItem('global_id', response.data.global_id);
            const config = await AuthService.getConfig()
            console.log('config',config)
            if(config?.data){
              localStorage.setItem('config', JSON.stringify(config.data));
            }
          }
          setUser(response.data.user); // Set user data
        } catch (err) {
          console.error('Authentication failed:', err);
        } finally {
          setLoading(false);
          setUser({uid:'tokenbaseduser'})
        }
      };
  
      authenticate();
    
  }, []);

  if (loading) {
    // Show a loading spinner or placeholder while determining auth state
    return <LoadingView/>;
  }


  return (
    <Router>
    <React.Suspense fallback={<div>Loading...</div>}>
   <Routes>

   <Route path="/lobby" element={<VideoCallLobby  user={user}/>} />
   <Route path="/video-interview" element={<VideoInterview  user={user}/>} />
   <Route
            path="/login"
            element={
              user ? (
                <Navigate to="/" replace /> // Redirect if user is logged in
              ) : (
                <AuthLayout>
                  <Login user={user} />
                </AuthLayout>
              )
            }
          />
    <Route path="/" element={<MainLayout handleLogout={handleLogout} user={user} />}>
     <Route path="/" element={ <LoanApplications  user={user} />} />
 
     <Route path="/new-loan-application" element={<NewLoanApplication  user={user}/>} />
     <Route path="/video-interview-rep" element={<VideoInterview  user={user}/>} />
     <Route path="/loan-application-details" element={<LoanApplicationDetails  user={user}/>} />
     
   </Route>
   <Route path="*" element={<Navigate to={user ? "/" : "/login"} replace />} />
   </Routes>
   </React.Suspense>
 </Router>
  );
}

export default App;

